import { range } from '@avcan/utils/array'
import { Box, IconButton } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { convert } from 'html-to-text'
import { CldImage, getCldImageUrl } from 'next-cloudinary'
import { useRouter } from 'next/router'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'
import { renderToString } from 'react-dom/server'
import { FaExpand } from 'react-icons/fa'
import { MdHistoryToggleOff } from 'react-icons/md'
import { FormattedMessage, useIntl } from 'react-intl'
import { Gallery, Item } from 'react-photoswipe-gallery'

import { FORECAST_PHOTO_OPENED } from '@avcan/constants/products/mixpanel'
import { InnerHTML } from 'components/misc'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { archiveHtml_en, archiveHtml_fr } from './ArchivalFragment'
import { useMedia, useReport } from './Context'
import { Summary } from './Summary'

import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css'
import 'photoswipe/dist/photoswipe.css'

const BREAKPOINTS = range(300, 3000, 300)
const ARCHIVE_HTML = {
    en: archiveHtml_en,
    fr: archiveHtml_fr,
}
const DATE_FORMAT = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
}

export const DetailSet = () => {
    const { summaries } = useReport()
    const images = useMedia()?.images

    if (!Array.isArray(summaries) || summaries.length === 0) {
        return (
            <h3>
                <FormattedMessage description="Layout product/forecast/details" defaultMessage="No details found." />
            </h3>
        )
    }

    return (
        <>
            {summaries.map(({ type, content }) => (
                <Summary key={type} title={type.display} titleKey={type.value}>
                    <Detail
                        type={type}
                        content={content}
                        images={
                            images && images.length
                                ? images.filter(image => image.tag === typeToTagMap.get(type.value))
                                : []
                        }
                    />
                </Summary>
            ))}
        </>
    )
}

const Detail = ({ type, content, images }) => {
    const { id } = useReport()
    const sendTrackEvent = useSendTrackEvent()

    const sectionImages = images.filter(image => image.tag === typeToTagMap.get(type.value))

    const onBeforeOpen = pswpInstance => {
        pswpInstance.on('change', () => {
            sendTrackEvent(FORECAST_PHOTO_OPENED, {
                forecastId: id,
                detailType: type.display,
                imageOrder: pswpInstance.currIndex + 1 + ' of ' + sectionImages?.length,
                imageUrl: pswpInstance.currSlide.data.src,
                imageCaption: pswpInstance.currSlide.data.caption,
            })
        })
    }

    return (
        <>
            <InnerHTML>{content}</InnerHTML>
            {sectionImages && (
                <div
                    style={{
                        ...styles.imageContainerDiv,
                        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                    }}
                >
                    <Gallery
                        plugins={pswpLightbox => {
                            // register plugin
                            const captionPlugin = new PhotoSwipeDynamicCaption(pswpLightbox, {
                                captionContent: slide => {
                                    return slide.data.caption
                                },
                                type: 'below',
                            })
                        }}
                        onBeforeOpen={onBeforeOpen}
                        options={{ bgOpacity: 0.87 }}
                    >
                        {sectionImages.map(image => {
                            return (
                                <LightboxImage
                                    key={image.id}
                                    image={image}
                                    galleryId={typeToTagMap.get(type)}
                                    type={type}
                                />
                            )
                        })}
                    </Gallery>
                </div>
            )}
        </>
    )
}

const LightboxImage = ({ image, type }) => {
    const intl = useIntl()
    const cloudNameRegex = /res\.cloudinary\.com\/([^/]+)/
    const cloudNameMatch = image.url.match(cloudNameRegex)
    const cloudName = cloudNameMatch ? cloudNameMatch[1] : 'avalanche-ca'
    const router = useRouter()
    const { locale } = router

    const srcset = BREAKPOINTS.map(breakpoint => {
        const cldImageUrl = getCldImageUrl(
            {
                src: image.url,
                width: breakpoint,
                height: image.height,
            },
            { cloud: { cloudName } }
        )
        return `${cldImageUrl} ${breakpoint}w`
    }).join(', ')

    // Set alt text. If there is alt text on the image then use that, otherwise use the caption.
    // If there is no caption then just use the section type.
    let altText = `Image ${type.display}`
    if (image.caption && image.caption !== '<p></p>') {
        altText = convert(image.caption)
    }
    if (image.altText) {
        altText = image.altText
    }
    let caption = (
        <div>
            {image.isArchived ? (
                <>{ARCHIVE_HTML[locale]}</>
            ) : (
                <>{image.dateTaken ? <strong>{intl.formatDate(image.dateTaken, DATE_FORMAT)}</strong> : null}</>
            )}
            {image?.caption ? (
                <div className="captionDiv" dangerouslySetInnerHTML={{ __html: image.caption }}></div>
            ) : (
                <p>{image.altText}</p>
            )}
            {image.credit && (
                <div>
                    <small>
                        {locale === 'en' ? 'Credit' : 'Crédit'}: {image.credit}
                    </small>
                </div>
            )}
        </div>
    )
    const captionHTML = renderToString(caption)

    return (
        <Item
            original={image.url}
            thumbnail={image.url}
            originalSrcset={srcset}
            width={image.width}
            height={image.height}
            alt={altText}
            cropped={true}
            caption={captionHTML}
        >
            {({ ref, open }) => (
                <div ref={ref} onClick={open} style={styles.photoSwipeLink}>
                    <CldImage
                        src={image.url}
                        sizes="33vw"
                        fill
                        crop="fill"
                        style={styles.cldImage}
                        config={{ cloud: { cloudName } }}
                        alt={altText}
                    />
                    <div style={styles.overlay}>
                        {image.isArchived ? (
                            <span style={styles.archiveText}>
                                <MdHistoryToggleOff fontSize={'1.5em'} style={styles.archiveTextIcon} />
                                <FormattedMessage defaultMessage="Archival" description="Image archival chip label" />
                            </span>
                        ) : (
                            <>{image.dateTaken ? <span>{intl.formatDate(image.dateTaken, DATE_FORMAT)}</span> : null}</>
                        )}
                        <IconButton style={styles.expandIconCSS}>
                            <FaExpand />
                            <Box sx={visuallyHidden}>
                                <FormattedMessage defaultMessage="expand" />
                            </Box>
                        </IconButton>
                    </div>
                </div>
            )}
        </Item>
    )
}

// Utils
const typeToTagMap = new Map([
    ['avalanche-summary', 'avalanche'],
    ['weather-summary', 'weather'],
    ['snowpack-summary', 'snowpack'],
])

// Styles
const styles = {
    imageContainerDiv: {
        display: 'grid',
        gap: '5px',
        marginTop: '1.5rem',
    },
    archiveText: {
        display: 'flex',
        alignItems: 'center',
    },
    archiveTextIcon: {
        marginRight: '4px',
    },
    photoSwipeLink: {
        aspectRatio: 1,
        position: 'relative',
        display: 'flex',
    },
    cldImage: {
        objectFit: 'cover',
    },
    expandIconCSS: {
        color: 'white',
        fontSize: '0.8rem',
        padding: 0,
        borderRadius: '50%',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        padding: '5px 10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        fontSize: '0.8rem',
    },
}
