import React from 'react'

import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

import { Item } from 'components/sidebar'

export const AvalancheTerrainRatings = () => {
    const { locale } = useRouter()

    return (
        <Item>
            <a
                href={`https://www.pc.gc.ca/${locale}/pn-np/mtn/securiteenmontagne-mountainsafety/avalanche/echelle-ratings?_ga=2.115167811.573438189.1667223637-385521292.1638363862`}
                target="_self"
            >
                <FormattedMessage defaultMessage="Avalanche Terrain Ratings" description="FX Parks Canada Sidebar" />
            </a>
        </Item>
    )
}

export const AvalancheTerrainMaps = () => {
    const { locale } = useRouter()

    return (
        <Item>
            <a
                href={`https://www.pc.gc.ca/${locale}/pn-np/mtn/securiteenmontagne-mountainsafety/avalanche/terrainsav-avterrain`}
                target="_self"
            >
                <FormattedMessage defaultMessage="Avalanche Terrain Maps" description="FX Parks Canada Sidebar" />
            </a>
        </Item>
    )
}

export const AvalancheForecasting = () => {
    const { locale } = useRouter()

    return (
        <Item>
            <a
                href={`https://www.pc.gc.ca/${locale}/pn-np/mtn/securiteenmontagne-mountainsafety/avalanche/prevision-forecasting?_ga=2.147783155.573438189.1667223637-385521292.1638363862`}
                target="_self"
            >
                <FormattedMessage defaultMessage="Avalanche Forecasting" description="FX Parks Canada Sidebar" />
            </a>
        </Item>
    )
}

export const HighwayAvalancheControlProgram = () => {
    const { locale } = useRouter()

    return (
        <Item>
            <a
                href={`https://www.pc.gc.ca/${locale}/pn-np/mtn/securiteenmontagne-mountainsafety/avalanche/routes-highways?_ga=2.124302158.573438189.1667223637-385521292.1638363862`}
                target="_self"
            >
                <FormattedMessage
                    defaultMessage="Highway Avalanche Control Program"
                    description="FX Parks Canada Sidebar"
                />
            </a>
        </Item>
    )
}

export const BasicSafetyInformation = () => {
    const { locale } = useRouter()

    return (
        <Item>
            <a
                href={`https://www.pc.gc.ca/${locale}/pn-np/mtn/securiteenmontagne-mountainsafety/avalanche?_ga=2.150467186.573438189.1667223637-385521292.1638363862`}
                target="_self"
            >
                <FormattedMessage defaultMessage="Basic Safety Information" description="FX Parks Canada Sidebar" />
            </a>
        </Item>
    )
}
