import * as React from 'react'
import { useRouter } from 'next/router'

import { useForecast, usePrinting } from '../Context'
import * as Owners from '@avcan/constants/products/owners'
import AvalancheQuebecInfo from './AvalancheQuebecInfo'
import LocaleWarning from './LocaleWarning'
import * as Alert from 'components/alert'
import InnerHTML from 'components/misc/InnerHTML'
import styles from './Notifications.module.css'

export default function Notifications() {
    const forecast = useForecast()
    const { notifications = [] } = forecast
    const printing = usePrinting()
    const { pathname } = useRouter()

    return (
        <div className={styles.Set}>
            {notifications.map((notification, index) => (
                <Notification key={index} {...notification} />
            ))}

            {/* Only show AvQ warning on forecast page and when it is an AvQ Forecast */}
            {pathname !== '/map/[[...path]]' &&
                forecast.owner &&
                forecast.owner.value === Owners.AVALANCHE_QUEBEC &&
                !printing && <AvalancheQuebecInfo />}

            {/* Only show locale warnging on forecast view */}
            {pathname !== '/map/[[...path]]' && pathname !== '/forecasts/[id]/print' && <LocaleWarning />}
        </div>
    )
}

function Notification({ type, content }) {
    const Component = Alert.forType(type)

    return (
        <Component>
            <InnerHTML>{content}</InnerHTML>
        </Component>
    )
}
