import React from 'react'

import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { differenceInDays } from 'date-fns'
import { useRouter } from 'next/router'

import { useReport, useForecast } from './Context'
import { Metadata, Entry, ShareEntry, TimestampEntry } from 'components/metadata'

ForecastMetadata.propTypes = {
    shareUrl: PropTypes.string,
}

export default function ForecastMetadata({ shareUrl }) {
    const intl = useIntl()

    let { dateIssued, validUntil } = useReport()

    const forecast = useForecast()
    const { locale } = useRouter()

    const TRANSLATIONS = {
        'avalanche-canada': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Avalanche Canada',
        }),
        'avalanche-quebec': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Avalanche Québec',
        }),
        'parks-jasper': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-byk': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-waterton': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-glacier': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        kananaskis: intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Kananaskis Mountain Rescue Program',
        }),
    }

    return (
        <Metadata>
            <TimestampEntry
                term={intl.formatMessage({
                    description: 'FX Metadata',
                    defaultMessage: 'Date Issued',
                })}
                value={formatDateWithTimezone(dateIssued, forecast?.report?.timezone, locale)}
            />
            <ValidUntil
                dateIssued={dateIssued}
                validUntil={validUntil}
                locale={locale}
                timezone={forecast?.report?.timezone}
            />
            <Entry
                term={intl.formatMessage({
                    description: 'FX Metadata',
                    defaultMessage: 'Prepared by',
                })}
            >
                {TRANSLATIONS[forecast?.owner?.value]}
            </Entry>
            {shareUrl && <ShareEntry url={shareUrl} />}
        </Metadata>
    )
}

// Components
ValidUntil.propTypes = {
    validUntil: PropTypes.instanceOf(Date).isRequired,
    dateIssued: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
}

function ValidUntil({ dateIssued, validUntil, locale, timezone }) {
    const intl = useIntl()
    const term = intl.formatMessage({
        description: 'FX Metadata',
        defaultMessage: 'Valid Until',
    })

    return (
        <Entry term={term}>
            {differenceInDays(dateIssued, validUntil) > FURTHER_NOTICE_DAYS ? (
                <span>
                    <FormattedMessage defaultMessage="Until further notice" description="FX Metadata" />
                </span>
            ) : (
                formatDateWithTimezone(validUntil, timezone, locale)
            )}
        </Entry>
    )
}
const FURTHER_NOTICE_DAYS = 7

function formatDateWithTimezone(dateString, timezone = 'America/Vancouver', locale) {
    const date = new Date(dateString)

    // Mapping of timezone to abbreviated display format
    const timezoneAbbreviations = {
        'America/Vancouver': {
            en: 'PT',
            fr: 'HP',
        },
        'America/Edmonton': {
            en: 'MT',
            fr: 'HM',
        },
        'America/Denver': {
            en: 'MT',
            fr: 'HM',
        },
        'America/Toronto': {
            en: 'ET',
            fr: 'HE',
        },
        'America/St_Johns': {
            en: 'NT',
            fr: 'HT',
        },
    }

    // First format the date without timezone
    const dateFormatter = new Intl.DateTimeFormat(locale, {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timezone,
        hour12: false,
        timeZoneName: undefined, // Don't include timezone in the formatted string
    })

    // Get the formatted date
    const formattedDate = dateFormatter.format(date)

    // Add the simplified timezone abbreviation
    const simplifiedTimezone = timezoneAbbreviations[timezone] || 'PT' // Default to PT if timezone not found
    return `${formattedDate} ${simplifiedTimezone[locale]}`
}
